<template>

  <div>   


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          
            <header class="modal-card-head">
              <p class="modal-card-title">Sign in to Xmarine</p>
              <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body">  

              <div class="form-group">      
                <input class="input" type="email" v-model="email" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Email address</label>
              </div>

              <div class="form-group">      
                <input class="input" autocomplete="off" type="password" v-model="password" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Password</label>
              </div>

              <div class=" buttons has-text-centered">                                 
                <!-- <button @click="signInByEmail()" class="button is-primary">Sign-in</button>          -->                        
                <!-- <button @click="launchResetPassword" class="button">Reset Password</button>
                <p>&nbsp;</p>
                <p class="passwordMessage" v-if="pwResetMsg != ''">{{pwResetMsg}}</p>  -->
              </div>

            </section>
            <footer class="modal-card-foot centered">
              <button :disabled="!showSigninButton || signInClicked" class="button is-primary is-medium" @click="signInByEmail()">Sign In</button>
              <button class="button is-medium" @click="close">Cancel</button>
            </footer>        

        </div>
      </div>            

            
      


  </div>

</template>



<script>

import firebase from 'firebase/app';
import { db } from '@/main.js';
import NProgress from 'nprogress';

export default {

  components: {

  },

  props: {

  },

  mounted() {
    
  },

  computed: {
    showSigninButton() {
      return this.passwordValid && this.emailIsValid;
    },
    emailIsValid() {
      return this.reg.test(this.email);
    },
    trimmedEmail() {
      return this.email.trim();
    },
    passwordValid() {
      return this.password.length > 5;
    },
  },

  data: function() {
    return {
      showModal: true,
      email: '',
      password: '',
      signInClicked: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }
  },

  methods: {

    close: function() {
      this.showModal = false;
      let result = {
        answer: 'Signin cancelled',
      };
      this.$emit('signinCancelled', result);
    },

    signInByEmail() {
      NProgress.start();
      var self = this;
      this.signInClicked = true;
      let result = {};
      firebase.auth().signInWithEmailAndPassword(self.trimmedEmail, self.password).then( user => {
          // self.$router.replace('my-dashboard');
          this.$emit('signinSuccess', result);
          NProgress.done();
        },
        error => {
          NProgress.done();
          console.log('Signin error:',error);
          alert('We couldn\'t sign you in. Please check your email and password.')
        }
      );
    },




  }


}

</script> 

<style lang="scss" scoped>

  .fa-spin {
    animation-duration: 0.8s; // or something else
  }
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #0a86e0;
  }
  
  .spinner {
    svg {
      font-size: 2rem;
      font-weight: bold;
      color: #0a86e0;
      margin-top: 1rem;
    }
  }

</style>
